<template>
    <v-card>
        <v-list rounded>
        <v-subheader>GUEST MENU</v-subheader>
        <v-list-item-group
            v-model="selectedItem"
            color="primary"
        >
            <v-list-item
            v-for="(item, i) in items"
            :key="i"
            >
            <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <router-link :to="item.link" class="nav-link">
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                </router-link>
            </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
        </v-list>
    </v-card>
</template>

<script>
  export default {
    name: 'GuestMenuCard',
    data: () => ({
        selectedItem: null,
        items: [
            { text: 'Register School', icon: 'mdi-account', link: 'register' },
            { text: 'School Admin Login', icon: 'mdi-login-variant', link: 'login' },
            { text: 'Recover Password', icon: 'mdi-lock', link: 'recover-password' },
            { text: 'Teacher Access', icon: 'mdi-location-enter', link: 'access' },
            { text: 'Student Access', icon: 'mdi-location-enter', link: 'access' },
        ],
    }),
  }
</script>

<style scoped>
.nav-link{
    text-decoration: none;
    color: #a5a084 !important;;
}
.router-link-active{
    color: rgb(240, 186, 69) !important;
    padding: 5px;
    font-weight: 700;
}
</style>
