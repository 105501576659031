import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      isLoggedIn:false,
      isTokenAccess:false,
      emailVerified:false,
  },
  mutations: {
    isLoggedIn: (state, value) =>
    {state.isLoggedIn = value},
    isTokenAccess: (state, value) =>
    {state.isTokenAccess = value},
    emailVerified: (state, value) =>
    {state.emailVerified = value},
    initializeStore(state){
        if (sessionStorage.getItem('authToken')) {
            state.isLoggedIn = true;
        }
        if (sessionStorage.getItem('accessToken') && !sessionStorage.getItem('authToken')) {
            state.isTokenAccess = true;
        }
    },
  },
  actions: {
  },
  modules: {
  }
})
