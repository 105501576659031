<template>
    <v-card>
        <v-list rounded>
        <v-subheader>USER MENU</v-subheader>
        <v-list-item-group
            v-model="selectedItem"
            color="primary"
        >
            <v-list-item v-if="userType === 'Teacher'">
                <v-list-item-icon>
                    <v-icon>mdi-typewriter</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <router-link to="scores" class="nav-link">
                        <v-list-item-title>Record Scores</v-list-item-title>
                    </router-link>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-icon>
                    <v-icon>mdi-printer</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <router-link to="sheets" class="nav-link">
                        <v-list-item-title>Print Result Sheets</v-list-item-title>
                    </router-link>
                </v-list-item-content>
            </v-list-item>
            <v-list-item @click="signout">
            <v-list-item-icon>
                <v-icon>mdi-minus-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>Sign Out</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
        </v-list>
    </v-card>
</template>

<script>
  export default {
    name: 'TokenUserMenuCard',
    data: () => ({
        selectedItem: null,
        userType:sessionStorage.getItem('utpc') ?? null,
    }),
    methods: {
        signout () {
            sessionStorage.removeItem('authToken')
            sessionStorage.removeItem('accessToken')
            sessionStorage.removeItem('utpc')
            localStorage.removeItem('school')
            sessionStorage.removeItem('loggedUser')
            this.$store.commit("isLoggedIn", false);
            this.$store.commit("isTokenAccess", false);
            this.$router.push('access')
        }
    }
  }
</script>

<style scoped>
.nav-link{
    text-decoration: none;
    color: #a5a084 !important;;
}
.router-link-active{
    color: rgb(240, 186, 69) !important;
    padding: 5px;
    font-weight: 700;
}
</style>
