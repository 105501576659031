<template>
  <v-app>
    <v-app-bar
      app
      color="#f5f5f5"
      class="noprint"
      src="/school3.png"
    >
        <template v-slot:img="{ props }">
            <v-img
            v-bind="props"
            gradient="to top right, rgba(255,255,200,.9), rgba(255,250,255,.9)"
            ></v-img>
        </template>

        <v-app-bar-nav-icon class="hovp" color="#e6205a" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <div v-if="school" class="align-center text-uppercase d-none d-sm-flex">
            <v-img
            alt="Logo"
            class="shrink mr-2"
            contain
            src="/favicon.png"
            transition="scale-transition"
            width="40"
            />
            <span style="color:black">MYSCH</span>
        </div>
        <v-spacer></v-spacer>

        <router-link v-if="user && !user.email_verified_at && emailVerified == false" to="verify-email" class="nav-link">Verify Your Email</router-link>

        <v-spacer></v-spacer>
      <v-btn
        text
        color="#e6205a"
      >
        <span class="mr-2 caption">v4.0.0</span>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      width="300"
      dark
      class="white--text noprint"
    >
      <guest-menu-card v-if="isLoggedIn === false && isTokenAccess === false"/>
      <token-user-menu-card v-if="isLoggedIn === false && isTokenAccess === true"/>
      <auth-menu-card v-if="isLoggedIn === true"/>
    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>
    <v-col class="text-right noprint hovp" v-if="isLoggedIn">
        <v-btn text @click.stop="drawer = !drawer">Show Menu &raquo;</v-btn>
    </v-col>
    <app-footer/>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import AppFooter from './components/AppFooter.vue';
import AuthMenuCard from './components/AuthMenuCard.vue';
import GuestMenuCard from './components/GuestMenuCard.vue';
import TokenUserMenuCard from './components/TokenUserMenuCard.vue';

export default {
    components: { AppFooter, AuthMenuCard, GuestMenuCard, TokenUserMenuCard },
    name: 'App',

    data: () => ({
        drawer: false,
        group: null
    }),
    computed: {
        ...mapState({
        isLoggedIn: (state) => state.isLoggedIn,
        isTokenAccess: (state) => state.isTokenAccess,
        emailVerified: (state) => state.emailVerified,
        }),
        school (){
            if (this.isLoggedIn == true) {
                return JSON.parse(localStorage.getItem('school'))
            }
            return JSON.parse(localStorage.getItem('school'))
        },
        user (){
            if (this.isLoggedIn == true) {
                return JSON.parse(sessionStorage.getItem('loggedUser'))
            }
            return JSON.parse(sessionStorage.getItem('loggedUser'))
        }
    },
    metaInfo: {
        meta: [
            { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
        ]
    },
    watch: {
        group () {
            this.drawer = false
        }
    },
    methods:{
    }
};
</script>

<style>
.v-card{
    box-shadow: #000;
}
</style>

<style scoped>
.nav-link{
    text-decoration: none;
    color: #000;
    background: rgb(255, 202, 242);
    padding: 3px 10px;
    border-radius: 10px;
}
</style>
