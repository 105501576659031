import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {path: '/about',name: 'About',component: () => import( '../views/About.vue')},
  {path: '/register',name: 'Register',component: () => import( '../views/Auth/Register.vue')},
  {path: '/login',name: 'Login',component: () => import( '../views/Auth/Login.vue')},
  {path: '/verify-email',name: 'VerifyEmail',component: () => import( '../views/Auth/VerifyEmail.vue')},
  {path: '/recover-password',name: 'RecoverPassword',component: () => import( '../views/Auth/RecoverPassword.vue')},
  {path: '/dashboard',name: 'Dashboard',component: () => import( '../views/App/Dashboard.vue')},
  {path: '/terms',name: 'Terms',component: () => import( '../views/App/Terms.vue')},
  {path: '/classes',name: 'Classes',component: () => import( '../views/App/Classes.vue')},
  {path: '/subjects',name: 'Subjects',component: () => import( '../views/App/Subjects.vue')},
  {path: '/gradings',name: 'Grades',component: () => import( '../views/App/Grades.vue')},
  {path: '/students',name: 'Students',component: () => import( '../views/App/Students.vue')},
  {path: '/scores',name: 'Scores',component: () => import( '../views/App/Scores.vue')},
  {path: '/sheets',name: 'ResultSheets',component: () => import( '../views/App/ResultSheets.vue')},
  {path: '/tokens',name: 'GenerateTokens',component: () => import( '../views/App/GenerateTokens.vue')},
  {path: '*',name: 'NotFound',component: () => import( '../views/App/NotFound.vue')},
  {path: '/access',name: 'MultiAccessLogin',component: () => import( '../views/MultiAccess/Login.vue')},
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
